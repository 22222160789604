import React, {lazy, memo, useState, useEffect} from "react";
import {withStyles} from "@material-ui/core";
import * as firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from "react-ga";

const styles = (theme) => ({
  main: {
    marginLeft: theme.spacing(3),
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 2,
    },
  },
});

const LoggedInComponent = lazy(() => import("./Main"));

function LoginHandler(props) {
  // const { classes } = props;
  const [firebase_id, setFirebase_id] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authState, setAuthState] = useState({ status: "loading" });

  useEffect(() => {
      return firebase.auth().onAuthStateChanged( async user => {
        if (user) {
          console.log(user);
          setFirebase_id(user.uid);

          ReactGA.set({
            userId: user.uid,
            // any data that is relevant to the user session
            // that you would like to track with google analytics
          });
          setIsLoading(false);

          const token = await user.getIdToken();
          const idTokenResult = await user.getIdTokenResult();
          const hasuraClaim =
            idTokenResult.claims["https://hasura.io/jwt/claims"];

          console.log(hasuraClaim);
          if (hasuraClaim) {
            setAuthState({ status: "in", user, token });
          } else {
            // Check if refresh is required.
            const metadataRef = firebase
              .database()
              .ref("metadata/" + user.uid + "/refreshTime");

            metadataRef.on("value", async (data) => {
              if(!data.exists) return
              // Force refresh to pick up the latest custom claims changes.
              const token = await user.getIdToken(true);
              setAuthState({ status: "in", user, token });
            });
          }
        } else {
          // No user is signed in.
          console.log(user);
          setFirebase_id(null);
          setAuthState({ status: "out" });

        }
      });
  }, [setFirebase_id, setIsLoading, setAuthState]);

  if (authState.status==="loading") {
      console.log('loading');
      return <CircularProgress/>
  } else {
      console.log('done loading');
      console.log(firebase_id);
      if (authState.status === "in") {
        return <LoggedInComponent firebase_id={firebase_id}/>
      } else {
          return <div>Logged out</div>
      }
  }

}
export default withStyles(styles, { withTheme: true })(memo(LoginHandler));