import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
import { ApolloProvider } from '@apollo/react-hooks';
// import {client} from "./shared/functions/apolloClient";
// import {auther} from "./shared/functions/auth"
import LoginHandler from "./logged_in/components/LoginHandler";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import * as firebase from "firebase";

import { useClearCache } from "react-clear-cache";

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';


const firebaseConfig = {
  apiKey: "AIzaSyCymGz8Mshhwc2iKiZQ518BuVHLDEN0ggY",
  authDomain: "planpipes.firebaseapp.com",
  databaseURL: "https://planpipes.firebaseio.com",
  projectId: "planpipes",
  storageBucket: "planpipes.appspot.com",
  messagingSenderId: "317210436763",
  appId: "1:317210436763:web:a8aac5e0c306eaac09523e",
  measurementId: "G-GRY795W145"
};

const trackingId = "UA-171869701-1";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const initFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
};

const createApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: 'https://planpipes-test.herokuapp.com/v1/graphql',
      headers: {
        'content-type': 'application/json',
        'x-hasura-access-key': 'HSWSHHH'
      }
    }),
    cache: new InMemoryCache(),
  });
};

// const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));
const stripePromise = loadStripe("pk_live_1hUeW5kl5flCxJGxHQ6jCS7H00VgUy2DzR");

function App() {
  if (!firebase.apps.length) {
    initFirebase();
    // firebase.initializeApp({});
  }

  const client = createApolloClient();

  const { isLatestVersion, emptyCacheStorage } = useClearCache({ duration: 60 * 1000 });


  useEffect(() =>{
    if (!isLatestVersion) {
      emptyCacheStorage()
    }
  }, [isLatestVersion, emptyCacheStorage]);

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            <ApolloProvider client={client}>
              <Switch>
                <Route path="/c">
                  {/*{firebase.auth().currentUser === null ? (*/}
                  {/*  <CircularProgress />*/}
                  {/*) : (<LoggedInComponent/>)*/}
                  {/*}*/}
                  {/*<LoggedInComponent/>*/}
                  <LoginHandler/>
                </Route>
                <Route>
                  <LoggedOutComponent />
                </Route>
              </Switch>
            </ApolloProvider>
          </Suspense>
        </MuiThemeProvider>
      </BrowserRouter>
    </Elements>
  );
}

serviceWorker.register();

export default App;
